<template>
  <div class="item" v-for="item in list" :key="item.id">
    <div class="right">
      <p class="date">{{ formateTime(item.created_at) }}</p>
      <p>{{ item.comment }}</p>
    </div>
    <div class="left" :class="{ red: isConsume(item.points) }">
      <p>{{ isConsume(item.points) ? "已使用" : "已獲得" }}</p>
      <p>{{ item.points > 0 ? `+${item.points}` : item.points }}</p>
    </div>
  </div>
</template>

<script>
import { formateTime } from "@/utils/tools";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const isConsume = (points) => {
      if (points > 0) return false;
      return true;
    };

    return {
      isConsume,
      formateTime,
    };
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  border-bottom: 1px solid var(--color-border);

  p {
    margin-bottom: 8px;
  }

  .right {
    .date {
      color: #00000099;
    }
  }
  .left {
    text-align: end;
  }
}
.red {
  color: #e02020;
}
</style>
