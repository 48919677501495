<template>
  <div class="container">
    <div class="input-area">
      <el-select v-model="currentMonth">
        <el-option
          v-for="item in months"
          :value="item.id"
          :label="item.name"
          :key="item.id"
        ></el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  props: {
    modelValue: {
      type: Number,
      default: 1,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const currentMonth = computed({
      get() {
        return props.modelValue;
      },
      set(newValue) {
        emit("update:modelValue", newValue);
      },
    });

    return {
      months: [
        {
          id: 1,
          name: "一月",
        },
        {
          id: 2,
          name: "二月",
        },
        {
          id: 3,
          name: "三月",
        },
        {
          id: 4,
          name: "四月",
        },
        {
          id: 5,
          name: "五月",
        },
        {
          id: 6,
          name: "六月",
        },
        {
          id: 7,
          name: "七月",
        },
        {
          id: 8,
          name: "八月",
        },
        {
          id: 9,
          name: "九月",
        },
        {
          id: 10,
          name: "十月",
        },
        {
          id: 11,
          name: "十一月",
        },
        {
          id: 12,
          name: "十二月",
        },
      ],
      currentMonth,
    };
  },
};
</script>

<style scoped lang="scss">
.input-area {
  padding: 16px 0;
  max-width: 150px;

  :deep(.el-input__inner) {
    background-color: #0000000f;
    border: none;
  }
}
</style>
