<template>
  <div class="point-show container">
    <div class="left">
      <div class="left-top center">
        <img src="@/assets/images/point-icon.svg" alt="我的點數" />
        <p class="text-center ml-2 mb-0">我的點數</p>
      </div>
      <div class="left-bottom">
        <span class="point-value">
          {{ formatMoney(Number(profile?.points) || 0) }}
        </span>
        <span class="point-unit">點</span>
      </div>
    </div>
    <div class="right">
      <p>積點使用後將不退還</p>
      <p class="remark mb-0">
        店家保留隨時變更、修改、暫停或終止積點活動之權利，若您不同意請勿使用積點。
      </p>
      <!-- <div class="right-top">
        <p>即將到期</p>
        <p>{{ expiryPoints }}點</p>
      </div>
      <div class="right-center">
        <p>點數將於{{ expiryDate }}失效</p>
      </div>
      <div class="right-bottom">
        <div class="record-bottun" @click="changePage">點數紀錄</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { formatMoney } from "@/utils/tools";

export default {
  setup() {
    const store = useStore();
    store.dispatch("member/getHompageProfileAction");

    const profile = computed(() => store.state.member.homepage);

    return {
      profile,
      formatMoney,
    };
  },
};
</script>

<style scoped lang="scss">
.point-show {
  width: 100%;
  background-color: var(--color-primary);
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;

  .left {
    width: 50%;
    background-color: var(--color-white);
    border-radius: 10px;
    /* padding: 12px 0; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .left-bottom {
      // padding: 6px 0 12px 0;
      color: var(--color-primary);
      text-align: center;
      vertical-align: bottom;

      .point-value {
        font-size: 36px;
        margin-right: 8px;

        @media (max-width: 525px) {
          font-size: 24px;
        }
      }
    }
  }

  .right {
    width: 50%;
    padding: 0 12px;
    color: var(--color-white);
    font-size: 12px;

    .remark {
      font-size: 10px;
    }
  }
}
</style>
