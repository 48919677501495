<template>
  <list-wrapper
    :list="list"
    :getList="getList"
    :pagination="pagination"
    :initLoading="isLoading"
  >
    <template #header>
      <point-show></point-show>
      <point-month-select v-model="month"></point-month-select>
    </template>
    <point-record :list="list" />
  </list-wrapper>
</template>

<script>
import { ref, watch } from "vue";
import dayjs from "dayjs";

import MemberService from "@/services/member.service";
import ListWrapper from "@/components/ListWrapper.vue";

import PointShow from "./components/PointShow.vue";
import PointMonthSelect from "./components/PointMonthSelect.vue";
import PointRecord from "./components/PointRecord.vue";

export default {
  components: {
    PointShow,
    PointMonthSelect,
    PointRecord,
    ListWrapper,
  },
  setup() {
    const pagination = ref({});
    const list = ref([]);
    const isLoading = ref(true);
    const month = ref(dayjs().month() + 1);

    const getList = async (page) => {
      const result = await MemberService.getPointProfile({
        page,
        limit: 10,
        month: month.value,
      });
      // console.log(result);
      pagination.value = result?.pagination;
      if (result.data?.length) {
        list.value.push(...result.data);
      }
    };

    const initList = async () => {
      isLoading.value = true;
      list.value = [];
      // getList();
      await getList(1);
      isLoading.value = false;
    };

    watch(
      () => month.value,
      async () => {
        await initList();
      },
      {
        immediate: true,
      }
    );

    return {
      getList,
      pagination,
      month,
      list,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped></style>
